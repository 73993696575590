import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    asrText:'>',
    semanticText:'',
    answerText:'>',
    readingAsrText:'>',
    entities:[],
    url: null,
    audioURL: null,
    showSemantic: false
};

const slice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setAsrText(state, action) {
      const { input } = action.payload;
      const { answer } = action.payload;

      if (input) { 
          console.log('Setting ASR result to : '+ input);
          state.asrText = input;
      }

      if (answer) {
        console.log('Setting Answer result to : '+ answer);
        state.answerText = answer;
      }
    },
    setReadingAsrText(state, action) {
      const { input } = action.payload;

      if (input) { 
          console.log('Setting ASR Reading View result to : '+ input);
          state.readingAsrText = input;
      }

    },
    setSemanticText(state, action) {
      const { intent } = action.payload;

      if (intent) { 
          console.log('Setting Semantic text to : '+intent.name);
          state.semanticText = intent.name;
      }

      const {entities } = action.payload;

      if (entities) {
        if (entities.length > 0)
           state.semanticText += '\n\nEntities : \n    '+entities[0].entity+' : '+entities[0].value;
        if (entities.length > 1)
           state.semanticText += '\n    '+entities[1].entity+' : '+entities[1].value;
        if (entities.length > 2)
           state.semanticText += '\n    '+entities[2].entity+' : '+entities[2].value;
        if (entities.length > 3)
           state.semanticText += '\n    '+entities[3].entity+' : '+entities[3].value;

      }
    },
    setUrl(state, action) {
      const { url } = action.payload;
      if (url) { 
        console.log('Setting url to : ',url);
        state.url = url;
      } else {
        console.log('WARNING: Setting URL, but value is null'); 
      }
    },
    setAudioURL(state, action) {
      const { audioURL } = action.payload;
      if (audioURL) { 
        console.log('Setting audioURL to new Value');
        state.audioURL = audioURL;
      } else {
        console.log('WARNING: Setting audioURL, but value is null'); 
      }
    },
    uploadFileRequest(state,action) {

    },
    uploadFileSuccess(state,action) {
      
    },
    uploadFileError(state,action) {

    }
  }
});

const uploadFileRequest = ({ file }) => {
  const data = new FormData();
  data.append('file', file, 'audio.mp3');

  axios.post(`/api/files`, data, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
    timeout: 30000,
  });
};

export const reducer = slice.reducer;

export const setAsrText = (response) => async (dispatch) => {
  dispatch(slice.actions.setAsrText(response));
};

export const setReadingAsrText = (response) => async (dispatch) => {
  dispatch(slice.actions.setReadingAsrText(response));
};

export const setSemanticText = (response) => async (dispatch) => {
  dispatch(slice.actions.setSemanticText(response));
};


export const setURL = (url) => async (dispatch) => {
  dispatch(slice.actions.setUrl(url));
};

export const setAudioURL = (url) => async (dispatch) => {
  dispatch(slice.actions.setAudioURL(url));
};

export const uploadFile = data => async dispatch => {

    try {
      dispatch(slice.actions.uploadFileRequest(data));
  
//      await uploadFileRequest(data);
 
      dispatch(slice.actions.uploadFileSuccess(data));
   
      alert('File uploaded successfully!');
    } catch (error) {

      dispatch(slice.actions.uploadFileError(data));

      if (error.response) {
        alert(error.response.data.message);
      } else {
        alert('Something went wrong while uploading this file');
      }
    }
};
  

export default slice;
