import { combineReducers } from '@reduxjs/toolkit';
import { reducer as audioReducer } from 'src/slices/audio';
import { reducer as readingReducer } from 'src/slices/reading';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';


const rootReducer = combineReducers({
  audio: audioReducer,
  reading: readingReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
});

export default rootReducer;
