import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    textToRead:'Je voudrais commander une caisse de vin de Fixin.'
};

const slice = createSlice({
  name: 'reading',
  initialState,
  reducers: {
    setNextText(state, action) {
      const { text } = action.payload;

      if (text) { 
          console.log('Setting next text to read to : '+text);
          state.textToRead = text;
      }
    } 
  }
});

export const reducer = slice.reducer;

export const getNextText = () => async (dispatch) => {
    console.log('Reading Slice -> getting next text to read from the server');
    const response = await axios.get('/Recording/fr-FR');
    console.log('Server response is',response.data);

    dispatch(slice.actions.setNextText(response.data));
};
  
export default slice;